import React from 'react';
import "./index.css"

function Footer() {
  return (
      <footer>
          <p className="copyright"><a href="https://rapidteam.io">	&copy; Rapid 2020</a></p>
      </footer>
  );
}

export default Footer;
