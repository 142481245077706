import React, { Component } from "react";
import TokenFormWrap from "../TokenFormWrap"

import "./index.css"
class TransferForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            toAddress: null,
            notOwner: false,
            delegateFields: [],
            delegateLength: 1,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    async componentDidMount() {

    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }



    handleSubmit(event) {
        event.preventDefault();
        this.submitTransaction()
    }

    submitTransaction = async () => {
        const { amount, toAddress } = this.state
        try {
            await this.props.contract.transfer(toAddress, amount)
            document.getElementById(this.props.id).reset();
        } catch (err) {
            alert(err.toString())
        }

    };


    render() {
        return (
            <TokenFormWrap title="ERC20 Transfer" helperText={"Transfer a token"}>
                <form id={this.props.id} onSubmit={this.handleSubmit}>
                    <label>Amount:</label>
                    
                    <br></br>
                    <input name="amount" type="number" onChange={this.handleChange} />
                    <br></br>
                    <br></br>

                    <label>To Address:</label>
                    <br></br>
                    <input name="toAddress" type="text" onChange={this.handleChange} />
                    <br></br>

                    <br></br>
                    <input type="submit" value="Submit" disabled={this.state.notOwner} />
                </form>
            </TokenFormWrap>
        );
    }
}
export default TransferForm;